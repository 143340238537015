import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";
import TextContent from "./TextContent";
import {graphql, StaticQuery} from "gatsby";

const TextBardziej = ({data, modifiers, modifiersImage, children}) => {
  const defaultModifiers = ['bardziej', 'bigger-2', 'bold', 'right'];
  const elementModifiers = [...defaultModifiers, ...modifiers];
  return (
    <>
      <TextContent modifiers={elementModifiers}>
        <div className={`bardziej bardziej--border ${modifiersImage.map(item => "bardziej--" + item).join(' ')}`}>
          <Img fixed={data.bardziejBorder.childImageSharp.fixed} />
        </div>
        {children}
      </TextContent>
    </>
  )
};

TextBardziej.defaultProps = {
  modifiers: [],
  modifiersImage: []
};

TextBardziej.propTypes = {
  modifiers: PropTypes.array,
  modifiersImage: PropTypes.array
};

export default props => (
  <StaticQuery
    query={graphql`
      query bardziejBorder {
        bardziejBorder: file(relativePath: { eq: "bardziej-border.jpg" }) {
          childImageSharp {
            fixed(width: 330, height: 462) {
              ...GatsbyImageSharpFixed
            }
          }
        },
      }
    `}
    render={data => <TextBardziej data={data} {...props} />}
  />
);
