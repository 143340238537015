import React, {Component} from 'react';
import withLayout from '../../layout';

import { StaticQuery, graphql } from "gatsby"

import Image from "../../components/layout/Sections/Image";
import ContactSlide from "../../components/Contact/ContactSlide";

import {FormattedHTMLMessage, injectIntl} from "react-intl";
import SectionColumn from "../../components/layout/Sections/SectionColumn";
import TextContent from "../../components/Typography/TextContent";
import TextBardziej from "../../components/Typography/TextBardziej";
import Spacer from "../../components/layout/Helpers/Spacer";
import List from "../../components/Typography/List";
import Section from "../../components/layout/Sections/Section";

class FSD extends Component {

  componentDidMount() {
    document.body.classList.remove('painted');
  }

  componentWillUnmount() {
    // document.body.classList.remove('painted');
  }

  render() {
    return (
      <>
        <StaticQuery
          query={graphql`
            query {
              goodbg: file(relativePath: { eq: "kompetencje/bg-good.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
            }
          `}
          render={data => (
            <div className="page page--small-space page--static page--www">

              <Section modifiers={['text', 'columns', 'padding-x']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <TextContent modifiers={['light-big']}>
                    <h1>
                      <FormattedHTMLMessage id="Czego oczekujemy?" />
                    </h1>
                  </TextContent>
                </SectionColumn>
                <SectionColumn>
                  <List items={[
                    "bardzo dobrej znajomości PHP5 oraz PHP7,",
                    "bardzo dobrej znajomości baz danych MySQL,",
                    "znajomości JavaScript (jQuery, ES6),",
                    "znajomości Vue i Reacta."
                  ]} />
                </SectionColumn>
              </Section>

              <Image image={data.goodbg.childImageSharp.fluid} />

              <Section modifiers={['text', 'columns', 'padding-x']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <TextContent modifiers={['light-big']}>
                    <h1>
                      <FormattedHTMLMessage id="Co oferujemy?" />
                    </h1>
                  </TextContent>
                </SectionColumn>
                <SectionColumn>
                  <List items={[
                    "Samorozwój - nie pozwolimy Ci pozostać na tym samym poziomie,",
                    "Zatrudnienie w oparciu o umowę o pracę lub kontrakt B2B,",
                    "Jesteśmy fair, zależy nam na Tobie i Twoim rozwoju, docenimy Twoje starania, mamy płaską strukturę i szybką decyzyjność,",
                    "Nastawiamy się na długotrwałą współpracę, wierzymy, że zespół jest naszą największą wartością, chcemy w Ciebie inwestować,",
                    "Konkurencyjne wynagrodzenie odpowiednie do Twoich umiejętności i wiedzy,",
                    "Benefity: multisport, finansowanie dalszej edukacji."
                  ]} />
                </SectionColumn>
              </Section>

              <Section modifiers={['text-bardziej', 'columns', 'padding-x']} modifiersLayout={['padding', 'top']}>
                <SectionColumn>
                  <TextBardziej>
                    <h1>
                      <strong>
                        <TextContent modifiers={['inherit']}>
                          <FormattedHTMLMessage id="My" />
                        </TextContent>
                        <Spacer contentTag="span" modifiers={['empty']}/>
                        <FormattedHTMLMessage id="Rozumiemy<br/> Bardziej" />
                      </strong>
                    </h1>
                  </TextBardziej>
                </SectionColumn>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id='<p>
                      Zainteresowanych zapraszamy do przesyłania CV na adres: <a href="mailto:praca@intellect.pl">praca@intellect.pl</a>,
                      prosimy o dołączenie do CV odpowiedniej klauzuli - informacje znajdziesz <a target="_blank" href="https://www.intellect.pl/upload/files/klauzula.pdf">tutaj</a>
                  </p>' />
                  </TextContent>
                </SectionColumn>
              </Section>

            </div>
          )}
        />
        <ContactSlide standalone={true} titleTag="h2" />
      </>
    )
  }
};

const customProps = {
  localeKey: 'career',
};

export default withLayout(customProps)(injectIntl(FSD));
