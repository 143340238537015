import React from 'react';
import Img from "gatsby-image";

import {FormattedHTMLMessage} from "react-intl";
import Section from "./Section";
import SectionColumn from "./SectionColumn";
import TextContent from "../../Typography/TextContent";

const Image = ({image, text, modifiers}) => (
  <section className="section-helper">
    <Section sectionTag="div" modifiers={modifiers} modifiersWrapper={['wider']}>
      <SectionColumn>
        <TextContent modifiers={['normal', 'center']}>
          <Img className={`image ${text ? 'image--with-text' : ''}`} fluid={image} />
        </TextContent>
      </SectionColumn>
    </Section>
    {text &&
      <Section sectionTag="div" modifiers={['overbg', 'padding-x']} modifiersWrapper={['wider']}>
        <SectionColumn>
          <TextContent modifiers={['light-big', 'white']}>
            <FormattedHTMLMessage id={text} />
          </TextContent>
        </SectionColumn>
      </Section>
    }
  </section>
);

Image.defaultProps = {
  text: "",
};

export default Image;
