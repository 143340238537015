import React from 'react';
import PropTypes from 'prop-types';

const Section = ({sectionTag: Tag, modifiers, modifiersWrapper, modifiersLayout, children}) => (
  <Tag className={`section ${modifiers.map(item => "section--" + item).join(' ')}`}>
    <div className={`wrapper ${modifiersWrapper.map(item => "wrapper--" + item).join(' ')}`}>
      <div className={`section__layout ${modifiersLayout.map(item => "section__layout--" + item).join(' ')}`}>
        {children}
      </div>
    </div>
  </Tag>
);

Section.defaultProps = {
  sectionTag: 'section',
  modifiers: [],
  modifiersWrapper: [],
  modifiersLayout: []
};

Section.propTypes = {
  modifiers: PropTypes.array,
  modifiersWrapper: PropTypes.array,
  modifiersLayout: PropTypes.array
};

export default Section;
