import React from 'react';
import PropTypes from "prop-types";

const SectionColumn = ({modifiers, children}) => (
  <div className={`section__column ${modifiers.map(item => "section__column--" + item).join(' ')}`}>
    {children}
  </div>
);

SectionColumn.defaultProps = {
  modifiers: []
};

SectionColumn.propTypes = {
  modifiers: PropTypes.array
};


export default SectionColumn;
